<template>
  <div class="slider" :class="[`slider-${type}`]" :disabled="disabled"></div>
</template>

<script>
import noUiSlider from 'nouislider'
import wNumb from 'wnumb'

export default {
  name: 'slider',
  emits: ['update:modelValue'],
  props: {
    modelValue: [String, Array, Number],
    disabled: {
      type: Boolean,
      default: null,
    },
    start: {
      type: [Number, Array],
      default: 0,
    },
    connect: {
      type: [Boolean, Array],
      default: () => [true, false],
    },
    range: {
      type: Object,
      default: () => {
        return {
          min: 0,
          max: 100,
        }
      },
    },
    type: {
      type: String,
      default: '',
    },
    step: {
      type: Number,
      default: 10,
    },
    options: {
      type: Object,
      default: () => {
        return {
          direction: 'ltr',
          format: wNumb({
            decimals: 0,
          }),
        }
      },
    },
  },
  data() {
    return {
      slider: null,
    }
  },
  methods: {
    createSlider() {
      noUiSlider.create(this.$el, {
        start: this.modelValue || this.start,
        connect: this.connect,
        range: this.range,
        step: this.step,
        ...this.options,
      })
      const slider = this.$el.noUiSlider
      slider.on('slide', () => {
        let value = slider.get()
        this.$emit('update:modelValue', value)
      })
    },
  },
  mounted() {
    this.createSlider()
  },
  watch: {
    value(newValue, oldValue) {
      const slider = this.$el.noUiSlider
      const sliderValue = slider.get()
      if (newValue !== oldValue && sliderValue !== newValue) {
        if (Array.isArray(sliderValue) && Array.isArray(newValue)) {
          if (oldValue.length === newValue.length && oldValue.every((v, i) => v === newValue[i])) {
            slider.set(newValue)
          }
        } else {
          slider.set(newValue)
        }
      }
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/color';
@import '@/assets/scss/variables';
@import '@/assets/scss/landing-page/slider';
</style>
